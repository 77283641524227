import React, { useState, useEffect } from "react";
// import Image from 'gatsby-image';
import PropTypes from "prop-types";

function Video({videoSrcURL, videoTitle, videoThumb, ...props}) {
	const [embed, setEmbed] = useState(false);

	// Handle the click
	// useEffect(() => {
	// 	// function handleClick(video) {
	// 		setEmbed(true)
	// 	// }
	// 	console.log('used effect')
	// })

	Video.propTypes = {
		videoSrcURL: PropTypes.string,
		videoTitle: PropTypes.string,
		videoThumb: PropTypes.string,
	};

	Video.defaultProps = {
		videoSrcURL: `djV11Xbc914`,
		videoTitle: ``,
		videoThumb: ``,
	};

	// The Video Embed to Render on click
	const video = (
		<iframe
			src={videoSrcURL+'?autoplay=1'}
			title={videoTitle}
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			frameBorder="0"
			webkitallowfullscreen="true"
			mozallowfullscreen="true"
			allowFullScreen
			/>
	);

	return (
		<div className="videoWrapper relative my-8">
			<div className="block absolute inset-0 w-full h-full border-2 border-retulBlue hover:border-white transition transition-colors duration-500 ease-in-out"
			style={{
				backgroundImage: 'url('+videoThumb+')',
			}}>
			<div
				className={ embed ? 'hidden' : 'visible play hover:opacity-100' }
				onClick={() => {
					setEmbed(true);
					}
				}
				>
			</div>
			{ /* const video should appear below on click */ }
			{ embed  && (video) }
			</div>
		</div>
	);

};

export default Video;
