import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="bg-transparent absolute inset-0 bottom-auto z-50">
      <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto p-8 md:py-8 uppercase">
        <Link to="/" className="flex items-center no-underline text-retulBlue">
          <h1 className="font-light text-4xl tracking-tight">{siteTitle}</h1>
        </Link>

        <button
          className="block md:hidden flex items-center px-3 py-2 text-white"
          onClick={() => toggleExpansion(!isExpanded)}
					title="Valmynd"
				>
            <title>Valmynd</title>
						&#9776;
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
{
//           <div className="text-sm">
//             <Link
//               to="/#hvad-er-bike-fit"
//               className="block md:inline-block mt-4 md:mt-0 mr-6 no-underline text-white"
//             >
//               Hvað er bike fit?
//             </Link>
//           </div>
}

					<div>
						<Link
							to="/#hafa-samband"
							className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-retulBlue hover:border-retulBlue hover:text-retulBlue hover:bg-transparent mt-4 lg:mt-0">
							Hafa samband
						</Link>
					</div>

        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
