import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

function Footer({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
		<footer className="container mx-auto p-16 text-center text-gray-300">
			<ul className="p-8 text-lg">
				<li className="sm:inline p-4 sm:p-8 uppercase"><a className="hover:text-retulBlue" href="https://www.facebook.com/bikefitsigga/" target="_blank">Facebook</a></li>
			{
			// 	<li className="sm:inline p-4 sm:p-8 uppercase"><a className="hover:text-retulBlue" href="https://www.instagram.com/" target="_blank">Instagram</a></li>
			}
			</ul>
			<span className="uppercase">
				<span className="sm:inline p-2">© 2019–{new Date().getFullYear()} Bikefit Sigga</span>
				<address className="sm:inline p-2">
					<a href="https://ja.is/kort/?x=355589&y=401523&q=Dalpr%C3%BD%C3%B0i%205%2C%20210%20Gar%C3%B0ab%C3%A6&type=map&page=1&z=11&d=hopun%3Ah10097056" target="_blank">Dalprýði 5, 210 Garðabæ</a>
				</address>
				<span className="sm:inline p-2">Sími <a href="tel:+3548321149">832 1149</a></span>
			</span>
		</footer>
  );
}

Footer.propTypes = {
};

Footer.defaultProps = {
};

export default Footer;
