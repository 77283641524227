import React from "react";
// import Modal from 'react-modal';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-plugin-modal-routing-3'


const Testimonial = ({ testimonial }) => {
	const photo = testimonial.frontmatter.thumb ? 
		testimonial.frontmatter.thumb.childImageSharp.gatsbyImageData
	: ( testimonial.frontmatter.photo ? 
		testimonial.frontmatter.photo.childImageSharp.gatsbyImageData
	 : null );

	return (

	<div className="testimonial w-1/2 md:w-1/3 cursor-pointer">
		<Link 
			to={"/"+testimonial.frontmatter.slug+"/"} 
			className="testimonial-link relative block pb-140% md:pb-100%"
			asModal
			>
			<div className="image w-full absolute inset-0 h-full z-0">
				<GatsbyImage image={photo} className="testimonial__photo w-full h-full block filter grayscale" alt={testimonial.frontmatter.name} />
			</div>

			<div className="copy absolute inset-x-0 bottom-0 z-10 p-4 pt-32 bg-gradient-to-t from-black">

				<div dangerouslySetInnerHTML={{__html: testimonial.frontmatter.excerpt}} className="md:text-xl font-light text-center"></div>

				<p className="text-gray-200 text-right text-sm md:text-base">
					— {testimonial.frontmatter.name}
				</p>

			</div>

		</Link>

	</div>

	)
}

export default Testimonial;