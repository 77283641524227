import React from "react"
import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby"

const Testimonials = () => {

	const data = useStaticQuery(graphql`
		query Testimonials {
			allMarkdownRemark(sort: {fields: frontmatter___name, order: ASC}) {
				edges {
					node {
						frontmatter {
							name
							photo {
								relativePath
								childImageSharp {
									gatsbyImageData(layout:FULL_WIDTH)
								}
							 }
							thumb {
								relativePath
								childImageSharp {
									gatsbyImageData(layout:FULL_WIDTH)
								}
							 }
							title
							excerpt
							slug
						}
						html
					}
				}
			}
		}
	`)

	return (

		<div className="testimonials">
			<div className="testimonials__container">

		<div className="text-4xl my-12 text-center">
			<h2>Umsagnir viðskiptavina</h2>
		</div>

		<div className="testimonial__cards flex flex-wrap flex-row">

			{data.allMarkdownRemark.edges.map(testimonial => (
				<Testimonial key={testimonial.node.frontmatter.name} {...testimonial} testimonial={testimonial.node} />
			))}

	</div>

		</div>
	</div>

	)
}

export default Testimonials