import React from "react";
import { graphql } from 'gatsby'

import axios from "axios"
import * as qs from "query-string"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Video from "../components/video";
import Testimonials from "../components/testimonials";

import comfort from "../images/comfort.jpg";
import injury from "../images/injury.jpg";
import perform from "../images/performance.jpg";
import comfortThumb from "../images/video_thumbs/Retul_Promo_Video_Comfort_Web.jpg";
import injuryThumb from "../images/video_thumbs/Retul+fit_Injury+Prevention_Web.jpg";
import performanceThumb from "../images/video_thumbs/Retul+fit_Performance_Web.jpg";

import sagan from "../images/backgrounds/Retul-road-cool.jpg";
import lab2 from "../images/backgrounds/Retul-lab-2.jpg";
import roadRain from "../images/backgrounds/Retul-road-rain.jpg";

import shoe from "../images/backgrounds/Retul-lab-shoe.jpg";

// function IndexPage()
class IndexPage extends React.Component {

	constructor(props) {
		// constructor for contact form
	  super(props)
	  this.domRef = React.createRef()
	  this.state = { feedbackMsg: null }
	}

	handleSubmit(event) {
	  // Do not submit form via HTTP, since we're doing that via XHR request.
	  event.preventDefault()
	  // Loop through this component's refs (the fields) and add them to the
	  // formData object. What we're left with is an object of key-value pairs
	  // that represent the form data we want to send to Netlify.
	  const formData = {}
	  Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))

	  // Set options for axios. The URL we're submitting to
	  // (this.props.location.pathname) is the current page.
	  const axiosOptions = {
	    url: this.props.location.pathname,
	    method: "post",
	    headers: { "Content-Type": "application/x-www-form-urlencoded" },
	    data: qs.stringify(formData),
	  }

	  // Submit to Netlify. Upon success, set the feedback message and clear all
	  // the fields within the form. Upon failure, keep the fields as they are,
	  // but set the feedback message to show the error state.
	  axios(axiosOptions)
	    .then(response => {
	      this.setState({
	        feedbackMsg: "Skilaboðin voru send!",
	      })
	      this.domRef.current.reset()
	    })
	    .catch(err =>
	      this.setState({
	        feedbackMsg: "Ekki tókst að senda skilaboðin.",
	      })
	    )
	}

	// Settings for Testimonials
	
	render() {
		
		var TestimonialSettings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		}
		
	return (
		<div>
			<Layout>

				<SEO
					title="Bikefit Sigga"
					keywords={[`bikefit`, `bike`, `fit`, `hjólamátun`, `Sigurður Karl`]}
					/>

				<div
					className="heroBanner h-screen bg-center bg-cover inset-0"
					id="top"
					>
					<div
						className="container mx-auto h-screen flex justify-left items-center max-w-6xl px-8 max-w-6xl"
					>
						<div className="w-full py-48 md:w-2/3 xl:w-1/2 sm:py-64 md:w-1/2">
							<h4 className="uppercase mb-4 text-retulBlue">Retül Bike Fit</h4>
							<h2 className="uppercase
								text-4xl lg:text-6xl text-white">Sérsniðið úr þínum mælingum</h2>
							<p className="text-xl text-gray-500">Retül Bike Fit gerir hjólreiðarnar ánægjulegri með því að auka afköst, minnka líkur á meiðslum og auka þægindi á hjólinu.</p>
						</div>
					</div>
				</div>

				<div className="container mx-auto">
					<div className="flex flex-wrap text-center justify-center content-center max-w-6xl mx-auto">

						<div className="w-full p-8 sm:w-1/2 md:w-1/3 mb-4 flex flex-col content-start">

							<div className="flex-1">
								<img src={perform} alt="Aukin þægindi" className="" />
								<h3 className="text-3xl">Aukin þægindi</h3>
								<p className="text-xl">Þægindi eru lykilatriði í að viðhalda tíma á hjólinu, sama á hvaða stigi þjálfunar maður&nbsp;er.</p>
							</div>

							<Video
								className="py-8"
								videoSrcURL="https://www.youtube.com/embed/L0xqQO5R0zc"
								videoTitle="Aukin þægindi"
								videoThumb={comfortThumb}
								/>

						</div>

						<div className="w-full p-8 sm:w-1/2 md:w-1/3 mb-4 flex flex-col content-start">

							<div className="flex-1">
								<img src={injury} alt="Fyrirbygging meiðsla" className="" />
								<h3 className="text-3xl">Fyrirbygging meiðsla</h3>
								<p className="text-xl">Rétt staða heldur líkamanum beinum á hjólinu og hjálpar þannig til við að fyrirbyggja meiðsli.</p>
							</div>

							<Video
								className="py-8"
								videoSrcURL="https://www.youtube.com/embed/NSQUTtMVrpw"
								videoTitle="Fyrirbygging meiðsla"
								videoThumb={injuryThumb}
								/>

						</div>

						<div className="w-full p-8 sm:w-1/2 md:w-1/3 mb-4 flex flex-col content-start">

							<div className="flex-1">
								<img src={perform} alt="Aukin afköst" className="" />
								<h3 className="text-3xl">Aukin afköst</h3>
								<p className="text-xl">Kjör staða eykur ekki aðeins þægindi, hún skilar líka hámarks úthaldi og afli.</p>
							</div>

							<Video
								className="py-8"
								videoSrcURL="https://www.youtube.com/embed/hB0t9O7vh2k"
								videoTitle="Aukin afköst"
								videoThumb={performanceThumb}
								/>
						</div>

					</div>
				</div>



					<div className="w-full relative" id="hvad-er-bike-fit">

						<img src={sagan} alt="Sagan" className="object-cover object-center mx-auto" />

						<div
							className="container p-8 max-w-6xl mx-auto sm:absolute sm:inset-0 flex justify-left items-center"
							>

						<div className="w-full sm:w-2/3 md:w-1/2">
							<h2 className="text-3xl md:text-5xl">Hvað er RETÜL FIT?</h2>

							<p className="text-lg">Retül Fit er meira en bara bike fit (hjólamátun), það er leið til að kynnast líkamanum þínum, því sem veldur honum sársauka, og hvernig rétt fit hjálpar þér að ná settum markmiðum.</p>

							<p className="text-lg">Retül kerfið notar þrívíða hreyfiupptöku til að mæla nákvæmlega allar hreyfingar upp á gráður og millimetra. Þannig safnast gögn saman sem hjálpa til við sérsniðna stillingu á hjóli, vali á búnaði, og að ná fram þeirri hjólaupplifun sem þú sækist eftir.
							</p>
						</div>
					</div>
				</div>

				<div className="w-full relative">

					<img src={lab2} alt="Bike Fit" className="object-cover object-center mx-auto" />

					<div
						className="container mx-auto md:absolute sm:inset-0 flex flex-col justify-between items-center p-8 md:p-0 max-w-6xl"
						>
						<div className="header md:flex md:flex-grow md:items-center md:p-4">
							<h2 className="text-3xl md:text-5xl xl:text-6xl">Hvernig virkar bike fit?</h2>
						</div>

						<div className="columns md:flex md:flex-row md:flex-wrap md:self-end md:px-4">

							<div className="column flex-1 md:p-4 py-4">
								<h3 className="text-2xl">Skref 1:</h3>
								<p className="md:text-lg">Fittið hefst á líkamsmati þar sem fittari gerir ráð fyrir takmörkunum líkamans, fyrri meiðslum og verkjum, og markmiðum þínum í hjólreiðum.</p>
							</div>

							<div className="column flex-1 md:p-4 py-4">
								<h3 className="text-2xl">Skref 2:</h3>
								<p className="md:text-lg">Næst setur fittari þig á hjólið (annað hvort þitt eigið eða þar til gert fit hjól) og setur LED punkta á 8 staði á líkamanum sem Retül kerfið fylgist með. Retül kerfið tekur upp staðsetningar punktana í rauntíma og safnar þrívíðum gögnum við hvern sveifarhring.
								</p>
							</div>

							<div className="column flex-1 md:p-4 py-4">
								<h3 className="text-2xl">Skref 3:</h3>
								<p className="md:text-lg">Rauntímagögn eru mötuð í Retül hugbúnað svo fittari geti skoðað tölurnar og gert hagræðingar til að koma þér í kjörna stöðu.</p>
							</div>

							<div className="column flex-1 md:p-4 py-4">
								<h3 className="text-2xl">Skref 4:</h3>
								<p className="md:text-lg">Þegar þú og fittari hafið fínstillt lokastöðuna gerir fittari kort af hjólastillingunni með Retül Zin tóli. Gögnunum og mælingunum frá Zin tólinu er safnað saman í skýrslu sem þú getur skoðað hvenær sem er eftir fittið.</p>
							</div>

						</div>
					</div>

				</div>

				<Testimonials />

				<div className="w-full lg:relative block">

					<img src={shoe} alt="Skór" className="object-cover object-center mx-auto" />

					<div
						className="container max-w-screen-lg mx-auto lg:absolute lg:inset-0 flex flex-col justify-around items-center p-8 md:p-0"
						>
						<div className="header mt-8 lg:flex lg:flex-grow lg:items-center">
							<h2 className="text-3xl mb-8 md:m-0 md:text-5xl xl:text-6xl">Verðskrá</h2>
						</div>

						<div className="prices md:flex md:flex-row md:flex-grow  justify-center">

							<div className="column  pb-4 md:p-6 text-center w-full md:w-2/3 lg:w-3/5">
								<h3 className="text-4xl mt-8 mb-8 md:mb-0">Fullt RETÜL bikefit</h3>
								<div className="md:flex md:flex-row md:flex-grow md:flex-wrap justify-center border-t-2 border-retulBlue md:border-0">

								<div className="column pb-4 md:p-6 md:pb-2 text-center w-full md:w-1/2 lg:w-1/2">
								<h4 className="text-2xl text-white mt-8">RACER og FJALLA- reiðhjól</h4>
								<p className="text-lg mt-0">
									<span className="price text-retulBlue text-4xl">42.500 kr.<br /></span>
								Tekur u.þ.b 3 klukkustundir.</p>
								</div>

								<div className="column pb-4 md:p-6 md:pb-2 text-center w-full md:w-1/2 lg:w-1/2">
							<h4 className="text-2xl text-white mt-8">TT eða þríþrautarhjól</h4>
								<p className="text-lg mt-0">
									<span className="price text-retulBlue text-4xl">46.000 kr.<br /></span>
								Tekur rúmar 3 klukkustundir.
							</p>
								</div>

								<div className="column text-center w-full">
									<p><strong>13.000 kr. staðfestingargjald, óendurkræft.</strong></p>
								</div>

								</div>
							</div>
							<div className="column pb-4 md:p-6 text-center w-full md:w-1/3 lg:w-2/5">
								<h3 className="text-4xl mt-8 pb-6 md:pb-0 mb-8 md:mb-0 border-b-2 border-retulBlue md:border-0">Uppfærsla á RETÜL Bikefitti</h3>
								<p className="text-lg">Nýtt reiðhjól til sögunnar, fínstilling á bikefitti eða önnur mál varðandi fittið.</p>
								<p className="text-lg mt-0">
									<span className="price text-retulBlue text-4xl">13.000 kr.</span> <br />
									klukkustundin.
								</p>
							</div>
						</div>

					</div>

				</div>

				<div className="w-full h-full relative">

					<img src={roadRain} alt="Road Rain" className="object-cover object-center mx-auto" />

					<div
						className="container mx-auto md:absolute md:inset-0 flex flex-row justify-center items-center p-8 md:p-0"
						id="hafa-samband"
						>

						<div className="form">
						<h2 className="text-4xl py-4">Hafa samband</h2>

						<p>Hafðu samband til að fá tíma og verð.</p>

							{this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}

							<form
								ref={this.domRef}
								name="Hafa samband"
								method="POST"
								data-netlify="true"
								data-netlify-honeypot="velmenni"
								onSubmit={event => this.handleSubmit(event)}
								>
								<p className="hidden">
									<input ref="form-name" type="hidden" name="form-name" value="Hafa samband" />
									<label>Ef þú ert vélmenni ættiru að fylla þennan reit út: <input ref="velmenni" name="velmenni" /></label>
								</p>
								<p>
									<label>Nafn: <input type="text" ref="name" name="name" required /></label>
								</p>
								<p>
									<label>Netfang: <input type="email" ref="email" name="email" required /></label>
								</p>
								<p>
									<label>Sími: <input type="tel" ref="tel" name="tel" /></label>
								</p>
								<p>
									<label>Hverju leitastu eftir úr bike fit? <input type="goal" ref="goal" name="goal" /></label>
								</p>
								<p>
									<label>Skilaboð: <textarea ref="message" name="message"></textarea></label>
								</p>
								<p>
									<button type="submit" className="border border-retulBlue border-solid px-4 py-1 uppercase float-right hover:text-retulBlue">Senda</button>
								</p>
							</form>


						</div>

					</div>
				</div>

			</Layout>
		</div>
	);
}
}

export default IndexPage;
